import React, { useEffect, useState, Suspense } from "react";
import Loader from "../components/Loader";
import Home from "./Home";
import Header from "../components/Header";
import { FirebaseAppProvider, AuthCheck } from "reactfire";
import "firebase/auth";
import { useSpring, animated } from "react-spring";

const App = ({ status }) => {
  console.log("Status Index: " + status);

  status == "loggedIn"
    ? window.localStorage.setItem("isSignedIn", true)
    : window.localStorage.setItem("isSignedIn", false);

  return null;
};

const AppWraper = () => {
  const [showLoader, setShowLoader] = useState(true);
  const duration = 5000;

  const propsStyle = useSpring({
    bottom: showLoader ? 0 : window.innerHeight,
    position: "absolute",
    backgroundColor: "#806290",
    height: "100vh",
    width: "100%",
    config: { duration: 1000 },
  });

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, duration);
  }, []);

  console.log("Show Loader: " + showLoader);
  console.log("Window: "+window);
  const firebaseConfig1 =  {
    apiKey: "AIzaSyDGHpy5No2Op5HH6PrKFkyez7RU-ooUu4A",
    authDomain: "gs-design-a2ba2.firebaseapp.com",
    projectId: "gs-design-a2ba2",
    storageBucket: "gs-design-a2ba2.appspot.com",
    messagingSenderId: "219403991357",
    appId: "1:219403991357:web:060660609447278f512d54",
    measurementId: "G-Y8CT4L51WK",
  }

  return (
    <div >
      <animated.div style={propsStyle}>
        <Loader />
      </animated.div>
      <div
        className={!showLoader ? "mainComponentsHidden" : "mainComponentsShown"}>
         
            <AuthCheck
              fallback={
                <div>
                  <App status="notLoggedIn" />
                  <Header location="notLoggedIn" />
                  <Home location="index" />
                </div>
              }
            >
              <div>
                <App status="loggedIn" />
                <Header location="loggedIn" />
                <Home location="home" />
              </div>
            </AuthCheck>

            {/* {/ <App /> /} */}
      </div>
    </div>
  );
};
export default AppWraper;
